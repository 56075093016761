import styled from '@emotion/styled'
import {
  space,
  width,
  maxWidth,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  borders,
  borderColor,
  borderRadius,
  color,
  boxShadow
} from 'styled-system'

const Input = styled('input')(
  space,
  width,
  maxWidth,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  {
    boxSizing: 'border-box'
  }
)

Input.defaultProps = {
  lineHeight: '1.0',
  fontSize: 0
}

export default Input
