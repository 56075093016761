module.exports.UP_KEYCODE = 38
module.exports.RIGHT_KEYCODE = 39
module.exports.DOWN_KEYCODE = 40
module.exports.GOOGLE_FONT_BASE_URL = 'https://fonts.googleapis.com/css'

module.exports.FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDxrcJ6IDRVkl8nAGHd6zg4ocbENWl9i3s',
  authDomain: 'compai.firebaseapp.com',
  databaseURL: 'https://compai.firebaseio.com',
  projectId: 'compai',
  storageBucket: 'compai.appspot.com',
  messagingSenderId: '523837044232',
  appId: '1:523837044232:web:5194ff27ce842776'
}
