import styled from '@emotion/styled'
import {
  space,
  width,
  maxWidth,
  color,
  borders,
  borderColor
} from 'styled-system'

const SectionBorder = styled('hr')(
  space,
  width,
  maxWidth,
  color,
  borders,
  borderColor,
  {
    boxSizing: 'border-box',
    height: 0,
    overflow: 'visible'
  }
)

SectionBorder.defaultProps = {
  width: 1,
  borderTop: '1px solid rgba(0,0,0,.1)',
  borderBottom: '0px solid transparent'
}

export default SectionBorder
