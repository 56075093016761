const Color = require('color')
const { GOOGLE_FONT_BASE_URL } = require('./constants')

const keyify = keys => keys.join('.')
const plusify = name => name.replace(/\s/g, '+')
const isString = s => typeof s === 'string'
const isNumber = s => typeof s === 'number'
const isArray = a => Array.isArray(a)
const isObject = o => typeof o === 'object' && !isArray(o)
const isNumeric = s => {
  if (s.startsWith('-')) {
    return /^\-\d+$/.test(s)
  } else {
    return /^\d+$/.test(s)
  }
}
const toPx = v => {
  if (isNumber(v)) {
    return v + 'px'
  }

  if (isString(v) && isNumeric(v)) {
    return v + 'px'
  }

  return v
}
const toRem = v => {
  if (isNumber(v)) {
    return v + 'rem'
  }

  if (isString(v) && isNumeric(v)) {
    return v + 'rem'
  }

  return v
}
const toEm = v => {
  if (isNumber(v)) {
    return v + 'em'
  }

  if (isString(v) && isNumeric(v)) {
    return v + 'em'
  }

  return v
}

const toGoogleFontUrl = pairing => {
  const body = [plusify(pairing.body.name), pairing.body.fontWeight].join(':')
  const heading = [
    plusify(pairing.heading.name),
    pairing.heading.fontWeight
  ].join(':')

  const families = [
    !body.startsWith('system-ui') && body,
    !heading.startsWith('system-ui') && heading
  ]
    .filter(Boolean)
    .join('|')

  return [GOOGLE_FONT_BASE_URL, '?family=', families].filter(Boolean).join('')
}

const flatten = (obj, parentKeys = []) =>
  Object.keys(obj).reduce((acc, key) => {
    const val = obj[key]
    const keys = parentKeys.concat([key])

    if (isString(val) || isNumber(val)) {
      acc[keyify(keys)] = val
      return acc
    } else if (isObject(val)) {
      return Object.assign(acc, flatten(val, keys))
    }

    if (isArray(val)) {
      val.forEach((v, i) => {
        const key = keyify(keys.concat([i]))
        acc[key] = v
      })
    }

    return acc
  }, {})

const toFullColorObject = c => {
  const color = Color(c)

  return {
    hex: color.hex(),
    hsl: color.hsl().string(),
    rgb: color.rgb().string()
  }
}

module.exports.toPx = toPx
module.exports.toEm = toEm
module.exports.toRem = toRem
module.exports.flatten = flatten
module.exports.isNumber = require('is-number')
module.exports.toGoogleFontUrl = toGoogleFontUrl
module.exports.toFullColorObject = toFullColorObject
module.exports.randomBool = (percentile = 1 / 2) => Math.random() < percentile
module.exports.randomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min)
