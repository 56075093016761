import styled from '@emotion/styled'
import {
  display,
  space,
  width,
  height,
  maxWidth,
  fontSize,
  color,
  borders,
  borderRadius
} from 'styled-system'

const Textarea = styled('textarea')(
  display,
  space,
  width,
  height,
  maxWidth,
  fontSize,
  color,
  borders,
  borderRadius,
  {
    overflow: 'auto',
    fontFamily: 'monospace, monospace'
  }
)

Textarea.defaultProps = {
  borderRadius: 3,
  fontSize: '14px'
}

export default Textarea
