import styled from '@emotion/styled'
import { color, display, width, maxWidth, height } from 'styled-system'

const Svg = styled('svg')(color, display, width, maxWidth, height, {
  boxSizing: 'border-box'
})

Svg.defaultProps = {
  display: 'block',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg'
}

export default Svg
