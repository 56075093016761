import styled from '@emotion/styled'
import {
  space,
  width,
  maxWidth,
  display,
  alignItems,
  justifyContent,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  color,
  borders,
  borderColor,
  borderRadius,
  opacity
} from 'styled-system'

const TextButton = styled('button')(
  space,
  width,
  maxWidth,
  display,
  alignItems,
  justifyContent,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  color,
  borders,
  borderColor,
  borderRadius,
  opacity,
  {
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    overflow: 'visible',
    textTransform: 'none',
    appearance: 'none',
    WebkitAppearance: 'none',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: 'color .25s ease-in',
    ':hover': {
      transition: 'color .25s ease-in',
      color: '#2F3BE4'
    }
  }
)

TextButton.defaultProps = {
  border: 0,
  fontWeight: 600,
  fontSize: '1',
  lineHeight: 1.25,
  textAlign: 'center',
  color: '#5761F5',
  display: 'inline-flex',
  alignItems: 'center'
}

export default TextButton
