/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Link } from 'gatsby'

import Layout from './layout'
import Logo from '../components/Logo'

const VoteLayout = ({ main, sidebar, footer }) => (
  <Layout>
    <div
      sx={{
        display: 'flex',
        height: ['50vh', '100vh', '100vh'],
        minHeight: ['auto', 'auto', '100vh'],
        flexWrap: ['wrap', 'nowrap']
      }}
    >
      <div
        sx={{
          flexGrow: '1',
          width: ['100%', 'auto'],
          height: '100%',
          overflowY: 'scroll'
        }}
      >
        {main}
      </div>
      <div
        sx={{
          bg: 'gray.2',
          height: '100%',
          width: ['100%', '24rem', '32rem'],
          minWidth: ['100%', '24rem', '32rem'],
          position: 'relative',
          borderLeft: '1px solid rgba(0,0,0,.1)'
        }}
      >
        <div
          sx={{
            height: '100%',
            overflowY: 'scroll'
          }}
        >
          <div sx={{ pt: [0, 0, 0], px: 3, pb: [4, 5, 6] }}>{sidebar}</div>
          <div
            sx={{
              borderTop: '1px solid',
              borderColor: 'gray.3',
              display: ['flex', 'none', 'none'],
              justifyContent: 'center',
              pb: 5
            }}
          >
            <Link
              sx={{
                display: ['block', 'block', 'block'],
                px: 3,
                py: 3,
                opacity: 0.5,
                transition: 'opacity .2s ease-in',
                ':hover': {
                  transition: 'opacity .25s ease-in',
                  opacity: 1
                }
              }}
              to="/"
            >
              <Logo color="gray.10" width={24} height={24} />
            </Link>
          </div>
        </div>
        <div
          sx={{
            bg: 'gray.2',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            borderTop: '1px solid rgba(0,0,0,.1)'
          }}
        >
          {footer}
        </div>
      </div>
    </div>
  </Layout>
)

export default VoteLayout
