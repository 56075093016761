export default {
  textStyles: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
      textDecoration: 'none'
    }
  },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'system-ui, sans-serif',
    monospace: 'Menlo, monospace'
  },
  fontSizes: ['.75rem', '1rem', '1.5rem', '2rem', '3rem', '4rem', '6rem'],
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#6663e7',
    indigo: [
      '#f2f2ff',
      '#dedefe',
      '#cacafc',
      '#a4a3f8',
      '#8280f1',
      '#6663e7',
      '#514bd9',
      '#4038c8',
      '#342ab1',
      '#2b1f96',
      '#221678',
      '#1a0f59'
    ],
    red: [
      '#ffe7ea',
      '#fecdd4',
      '#fdb4be',
      '#f88193',
      '#ed556f',
      '#dc3255',
      '#c61a43',
      '#ab0a39',
      '#8e0132',
      '#71002e',
      '#540027'
    ],
    blue: [
      '#E3EDF5',
      '#BFDBF4',
      '#9BC8F2',
      '#579DEE',
      '#1E73E7',
      '#0055DC',
      '#004BCE',
      '#0040BC',
      '#0036A5',
      '#002C8A',
      '#00236E'
    ],
    violet: [
      '#EFE4F5',
      '#E3C8F3',
      '#D7ABF2',
      '#BE74ED',
      '#A546E6',
      '#8E22DB',
      '#7A09CC',
      '#6A00B9',
      '#5B00A0',
      '#4B0084',
      '#3A0066'
    ],
    pink: [
      '#F5E3F2',
      '#F3BFEA',
      '#F29CE2',
      '#ED58CE',
      '#E61FB9',
      '#DB00A7',
      '#CC0099',
      '#B90089',
      '#A00076',
      '#840061',
      '#66004B'
    ],
    yellow: [
      '#FFFD9E',
      '#FDFB86',
      '#FCF96F',
      '#F5F140',
      '#ECE613',
      '#DED600',
      '#CBC200',
      '#B2A800',
      '#928800',
      '#6E6400',
      '#474000'
    ],
    orange: [
      '#FFD4B9',
      '#FFC49F',
      '#FFB485',
      '#FD9654',
      '#FA7D2A',
      '#F36909',
      '#E96300',
      '#D96100',
      '#BF5A00',
      '#934900',
      '#452400'
    ],
    green: [
      '#E1F5F0',
      '#C6F3E9',
      '#ABF2E2',
      '#75EDD2',
      '#40E6C1',
      '#0FDBAE',
      '#00CB9F',
      '#00B890',
      '#009F7C',
      '#008266',
      '#00634E'
    ],
    gray: [
      '#FFFFFF',
      '#FcFcFD',
      '#F9F9FB',
      '#EfEfF4',
      '#e3e3ea',
      '#d4d3db',
      '#bfbfc7',
      '#a6a5ad',
      '#86868c',
      '#606065',
      '#363538',
      '#0a0a0a'
    ]
  },
  lineHeights: {
    heading: 1.25,
    body: 1.5
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body'
    },
    h1: {
      fontSize: [3, 4, 5],
      fontFamily: 'heading',
      lineHeight: 'heading'
    },
    h2: {
      fontSize: [2, 3, 4],
      fontFamily: 'heading',
      lineHeight: 'heading'
    },
    h3: {
      fontSize: [2, 2, 3],
      fontFamily: 'heading',
      lineHeight: 'heading'
    },
    h4: {
      fontSize: 2,
      fontFamily: 'heading',
      lineHeight: 'heading'
    },
    h5: {
      fontSize: 1,
      fontFamily: 'heading',
      lineHeight: 'heading'
    },
    h6: {
      fontSize: 0,
      fontFamily: 'heading',
      lineHeight: 'heading'
    },
    hr: {
      border: 'thin solid #ccc',
      my: [3, 4, 5]
    },
    a: {
      color: 'primary',
      textDecoration: 'none'
    },
    p: {
      maxWidth: '34em',
      lineHeight: 'body',
      fontSize: 18
    },
    pre: {
      backgroundColor: '#fafafa',
      p: 3
    },
    li: {
      lineHeight: 'body'
    },
    img: {
      display: 'block',
      maxWidth: '100%'
    }
  }
}
