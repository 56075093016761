import styled from '@emotion/styled'
import {
  space,
  width,
  height,
  maxWidth,
  display,
  alignItems,
  justifyContent,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  color,
  borders,
  borderColor,
  borderRadius,
  opacity,
  boxShadow
} from 'styled-system'

const Button = styled('button')(
  space,
  width,
  height,
  maxWidth,
  display,
  alignItems,
  justifyContent,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  color,
  borders,
  borderColor,
  borderRadius,
  opacity,
  boxShadow,
  {
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    overflow: 'visible',
    textTransform: 'none',
    WebkitAppearance: 'button',
    whiteSpace: 'nowrap',
    '&:disabled': {
      opacity: 0.8
    }
  }
)

Button.defaultProps = {
  bg: 'black',
  color: 'white',
  border: 0,
  fontSize: 0,
  lineHeight: '1.0',
  mt: 0,
  mb: 0,
  mr: 0,
  ml: 0,
  textAlign: 'center',
  px: 3,
  py: 2
}

export default Button
