import React from 'react'

import TextButton from './TextButton'

export default ({ onClick, children, ...props }) => (
  <TextButton
    py={3}
    width={1 / 3}
    justifyContent="center"
    bg="transparent"
    color="black"
    css={{
      borderRight: '1px solid rgba(0,0,0,.1)',
      '&:last-child': {
        //borderRight: 'none'
      }
    }}
    onClick={onClick}
    {...props}
  >
    {children}
  </TextButton>
)
