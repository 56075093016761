import styled from '@emotion/styled'
import {
  space,
  width,
  maxWidth,
  display,
  alignItems,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  color
} from 'styled-system'

const Label = styled('label')(
  space,
  width,
  maxWidth,
  display,
  alignItems,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  color,
  {
    whiteSpace: 'nowrap',
    verticalAlign: 'middle'
  }
)

Label.defaultProps = {
  fontWeight: 600,
  fontSize: 0,
  lineHeight: 1,
  mr: 3,
  display: 'flex',
  alignItems: 'center',
  minWidth: 96
}

export default Label
