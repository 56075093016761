import React from 'react'
import { Helmet } from 'react-helmet'

export default ({
  title = 'Acorns',
  description = 'Generative Parametric Design',
  imgUrl = ''
}) => (
  <Helmet>
    <title>{title}</title>
    <meta name="title" content={title} />
    <meta name="description" content={description} />

    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://components.ai" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={imgUrl} />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://components.ai" />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={imgUrl} />

    <meta
      name="google-site-verification"
      content="ZtAk_wIKvMGnE7NjxP3Ty6MqSrkjVFBRHtIIY_47Ux0"
    />

    <link
      rel="shortcut icon"
      type="image/png"
      href="https://firebasestorage.googleapis.com/v0/b/compai.appspot.com/o/icon.png?alt=media&token=b30065d5-02ac-41ca-a3b4-ce4df964e758"
    />
  </Helmet>
)
