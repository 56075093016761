import React from 'react'
import Div from '../elements/Div'

const Flex = ({ ...props }) => {
  return <Div {...props}>{props.children}</Div>
}

Flex.defaultProps = {
  display: 'flex',
  alignItems: 'center'
}

export default Flex
