import React from 'react'
import { ThemeProvider } from 'theme-ui'
import { Global } from '@emotion/core'

import Head from './head'
import theme from '../theme'

export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <Head />
      <Global
        styles={{
          '*': {
            boxSizing: 'border-box'
          },
          body: {
            margin: 0,
            fontSize: '16px',
            fontFamily: 'system-ui, sans-serif'
          },
          '@font-face': {
            fontFamily: 'etc-trispace',
            src:
              "url('../fonts/ETCTrispaceVariable.woff2') format('woff2-variations')"
          }
        }}
      />
      {children}
    </>
  </ThemeProvider>
)
