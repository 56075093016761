import styled from '@emotion/styled'

import {
  space,
  width,
  height,
  minHeight,
  maxWidth,
  position,
  display,
  flex,
  flexWrap,
  alignItems,
  justifyContent,
  bottom,
  top,
  right,
  left,
  fontSize,
  fontFamily,
  textAlign,
  color,
  colorStyle,
  borders,
  borderColor,
  borderRadius,
  backgroundImage,
  boxShadow,
  opacity,
  gridTemplateColumns
} from 'styled-system'

const Div = styled('div')(
  space,
  width,
  height,
  minHeight,
  maxWidth,
  position,
  display,
  flex,
  flexWrap,
  alignItems,
  justifyContent,
  position,
  bottom,
  top,
  right,
  left,
  bottom,
  fontSize,
  fontFamily,
  textAlign,
  color,
  colorStyle,
  borders,
  borderColor,
  borderRadius,
  backgroundImage,
  boxShadow,
  opacity,
  gridTemplateColumns,
  {
    boxSizing: 'border-box'
  }
)

Div.defaultProps = {
  width: 1
}

export default Div
