import React from 'react'
import { Delaunay } from 'd3-delaunay'
import seedrandom from 'seedrandom'
import Div from '../elements/Div'
import { randomInt } from '../lib/util'
import { sample } from 'lodash'

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const seed = sample(['skullcat', 'rad', 'sparkle', 'fun', 'alpine'])
const height = 32
const width = 32
const viewBoxHeight = 32
const viewBoxWidth = 32
const strokeWidth = randomInt(1, 1)
const pointStrokeWidth = 0

const pointsCount = randomInt(1, 2)
const random = seedrandom(seed)
const coordinates = [[0, 0], [32, 32], [0, 32], [32, 0]]

for (let i = 0; i < pointsCount; ++i) {
  let randomPoint = [
    randomInt(randomInt(0, viewBoxWidth), viewBoxWidth * random()),
    randomInt(randomInt(0, viewBoxHeight), viewBoxHeight * random())
  ]
  coordinates.push(randomPoint)
}

const pointsInt = randomInt(0, 100)

const pointsRangeMax = 8

const borderWidth = 4

const delaunay = Delaunay.from(coordinates)

const Logo = ({ ...props }) => {
  return (
    <Div {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={0 + ' ' + 0 + ' ' + viewBoxWidth + ' ' + viewBoxHeight}
        width={32}
        height={32}
        css={{ color: 'currentColor', maxWidth: '100%', maxHeight: '100%' }}
      >
        <path
          stroke="currentColor"
          strokeWidth={strokeWidth + 'px'}
          fill="transparent"
          d={delaunay.render()}
        />
        <path
          stroke="currentColor"
          strokeWidth={borderWidth + 'px'}
          fill="transparent"
          d={delaunay.renderHull()}
        />
        <path
          stroke="currentColor"
          strokeWidth={pointStrokeWidth + 'px'}
          fill="transparent"
          d={delaunay.renderPoints()}
        />
      </svg>
    </Div>
  )
}

Logo.defaultProps = {
  height: '100%'
}

export default Logo
